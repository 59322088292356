/* 基础容器样式 */
.privacy-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #00c6fb 0%, #005bea 100%);
  color: #2c3e50;
  padding: 20px;
}

/* 内容容器样式 */
.privacy-content-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background: white;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

/* 头部样式 */
.privacy-header {
  text-align: center;
  margin-bottom: 40px;
}

.privacy-header h1 {
  font-size: 36px;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.privacy-header p {
  font-size: 18px;
  color: #666;
}

/* 主要内容样式 */
.privacy-content {
  color: #333;
  line-height: 1.8;
}

.privacy-content h2 {
  font-size: 24px;
  color: #1a1a1a;
  margin-top: 40px;
  margin-bottom: 20px;
}

.privacy-content p {
  margin-bottom: 20px;
  font-size: 16px;
}

.privacy-content ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

.privacy-content li {
  margin-bottom: 12px;
}

.privacy-content a {
  color: #00c6fb;
  text-decoration: none;
  transition: color 0.3s ease;
}

.privacy-content a:hover {
  color: #005bea;
}

/* 页脚样式 */
.privacy-footer {
  margin-top: 60px;
  text-align: center;
  color: #666;
  font-size: 14px;
}

/* 返回按钮样式 */
.back-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: #f1f5f9;
  color: #64748b;
  text-decoration: none;
  border-radius: 12px;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.back-button:hover {
  background: #e2e8f0;
  color: #00c6fb;
  transform: translateY(-2px);
}

.back-icon {
  font-size: 20px;
}

/* 语言切换器样式 */
.language-switcher {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.language-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.language-button:hover {
  background: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.language-icon {
  font-size: 20px;
  color: #00c6fb;
}

.language-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 180px;
}

.language-option {
  padding: 10px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  color: #333;
}

.language-option:hover {
  background: #f0f9ff;
  color: #00c6fb;
}

.language-option.active {
  background: #e0f7ff;
  color: #00c6fb;
  font-weight: 500;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .privacy-content-wrapper {
    padding: 30px 20px;
  }

  .privacy-header h1 {
    font-size: 28px;
  }

  .privacy-header p {
    font-size: 16px;
  }

  .privacy-content h2 {
    font-size: 20px;
  }

  .privacy-content p,
  .privacy-content li {
    font-size: 15px;
  }

  .language-switcher {
    top: 10px;
    right: 10px;
  }

  .language-button {
    padding: 8px 12px;
    font-size: 13px;
  }

  .language-menu {
    min-width: 160px;
  }
} 
/* 基础样式 */
.app-landing {
  min-height: 100vh;
  background: linear-gradient(135deg, #00c6fb 0%, #005bea 100%);
  color: #2c3e50;
}

/* 头部样式 */
.app-header {
  text-align: center;
  padding: 80px 20px;
  color: white;
  max-width: 1000px;
  margin: 0 auto;
}

.app-icon {
  width: 140px;
  height: 140px;
  border-radius: 32px;
  margin-bottom: 32px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.app-header h1 {
  font-size: 56px;
  font-weight: 700;
  margin: 0;
  letter-spacing: -0.5px;
}

.tagline {
  font-size: 28px;
  margin: 20px 0;
  opacity: 0.9;
}

.subtitle {
  font-size: 20px;
  line-height: 1.6;
  max-width: 800px;
  margin: 24px auto 40px;
  opacity: 0.8;
}

/* 主要特点区域 */
.key-features {
  background: white;
  padding: 80px 20px;
}

.key-features h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 50px;
  color: #1a1a1a;
}

.features-list {
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-2px);
  background: #f1f3f5;
}

.check-icon {
  color: #00c6fb;
  font-size: 20px;
  flex-shrink: 0;
}

/* 游戏画面区域 */
.gallery-section {
  padding: 80px 20px;
  background: #f8f9fa;
}

.gallery-section h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 50px;
  color: #1a1a1a;
}

.screenshots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.screenshot-frame {
  background: white;
  padding: 16px;
  border-radius: 24px;
  box-shadow: 0 20px 40px -10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.screenshot-frame:hover {
  transform: scale(1.03);
}

.screenshot-frame img {
  width: 100%;
  border-radius: 16px;
}

/* 描述区域 */
.description-section {
  padding: 80px 20px;
  background: white;
}

.description-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.description-content p {
  font-size: 18px;
  line-height: 1.8;
  color: #4a5568;
  margin-bottom: 24px;
}

/* 语言支持区域 */
.language-support {
  padding: 60px 20px;
  background: #f8f9fa;
}

.language-support h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 40px;
  color: #1a1a1a;
}

.language-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  max-width: 800px;
  margin: 0 auto;
}

.language-list span {
  padding: 8px 16px;
  background: white;
  border-radius: 20px;
  color: #4a5568;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.language-list span:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #00c6fb;
}

/* 页脚样式 */
.app-footer {
  text-align: center;
  padding: 40px 20px;
  background: white;
}

.privacy-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: #f1f5f9;
  color: #64748b;
  text-decoration: none;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.privacy-button:hover {
  background: #e2e8f0;
  color: #00c6fb;
  transform: translateY(-2px);
}

.privacy-icon {
  font-size: 20px;
}

.copyright {
  margin-top: 24px;
  margin-bottom: 12px;
  color: #94a3b8;
  font-size: 14px;
}

/* 备案号样式 */
.beian-link {
  display: block;
  color: #94a3b8;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.beian-link:hover {
  color: #00c6fb;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .app-header {
    padding: 60px 20px;
  }

  .app-icon {
    width: 120px;
    height: 120px;
  }

  .app-header h1 {
    font-size: 42px;
  }

  .tagline {
    font-size: 24px;
  }

  .subtitle {
    font-size: 18px;
  }

  .app-store-badge {
    padding: 14px 28px;
  }

  .store-icon {
    font-size: 24px;
  }

  .features-list {
    grid-template-columns: 1fr;
  }

  .screenshots-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .description-content p {
    font-size: 16px;
  }

  .language-list span {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .screenshots-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* App Store 按钮样式 */
.app-store-badge {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  background: black;
  color: white;
  padding: 16px 32px;
  border-radius: 16px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.app-store-badge:hover {
  transform: scale(1.05);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.3);
}

.store-icon {
  font-size: 28px;
}

/* 语言切换器样式 */
.language-switcher {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.language-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.language-button:hover {
  background: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.language-icon {
  font-size: 20px;
  color: #00c6fb;
}

.language-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 180px;
}

.language-option {
  padding: 10px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  color: #333;
}

.language-option:hover {
  background: #f0f9ff;
  color: #00c6fb;
}

.language-option.active {
  background: #e0f7ff;
  color: #00c6fb;
  font-weight: 500;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .language-switcher {
    top: 10px;
    right: 10px;
  }

  .language-button {
    padding: 8px 12px;
    font-size: 13px;
  }

  .language-menu {
    min-width: 160px;
  }
} 